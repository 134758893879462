@import "abstracts/mixins";

.button {
  @include focus-indicator-disappear;
  @include font-body-regular;
  @include reset-button;

  background-color: $white;
  border-radius: 4px;
  color: $error;
  padding: 0px 4px;

  &KeyboardFocused {
    @include focus-indicator-appear;
  }
}