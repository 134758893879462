// Converts pixel measurements to rem
@function rem-calc($value) {
  @return ($value / 16px) * 1rem;
}

// Darkens a color by mixing it with black
@function shade($color, $percent) {
  @return mix(black, $color, $percent);
}

// Tints a color by mixing it with white
@function tint($color, $percent) {
  @return mix(white, $color, $percent);
}

