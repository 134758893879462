.Link {
  @include focus-indicator-appear;
  @include focus-indicator-disappear;

  border-radius: 4px;
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: $secondary;
  }

  &:visited {
    color: $visited;
  }

  &--disabled {
    color: $dark-grey-blue;
    text-decoration: underline;
  }
}

.TextLink {
  @include focus-indicator-appear;
  @include focus-indicator-disappear;
  @include font-medium-uppercase;

  border-radius: 4px;
  color: $primary;

  &:hover {
    color: $secondary;
  }

  &--disabled {
    @include font-medium-uppercase;

    color: $secondary;
  }
}
