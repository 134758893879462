.MarkerControl {
  @include focus-indicator-disappear;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  box-shadow: 0 1px 3px $box-shadow;

  &--keyboard-focused {
    &:focus {
      @include focus-indicator-appear-external-focus;
      background-color: $light-blue;
    }
  }

  &--disabled {
    background-color: $light-grey;
  }

  &:hover {
    background-color: $light-blue;
  }

  &_icon {
    font-size: 1.5rem;
    margin: auto;
  }
}
