.moreOptionsLoader {
  position: relative;
  justify-content: center;
  align-items: center;
  background: $white;
  display: flex;

  .borderLine {
    position: absolute;
    top: calc(50% - 1px);
    height: 1px;
    width: 100%;
    background: $primary
  }

  .moreOptionsButton {
    background: $white;
    padding: 0 6px;
    justify-content: center;
    flex-shrink: 1;
    z-index: 1;

    button {
      padding: 4px 6px;
    }
  }
}