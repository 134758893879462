$border-width: 1px;

.MapPreview {
  align-items: center;
  background-color: $light-grey;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  &__image {
    border-radius: 4px;
    display: block;
    height: 75px;
    width: 209px;
  }

  &__map {
    position: relative;
  }

  &__marker {
    align-items: center;
    background-color: $light-grey;
    border-radius: 4px;
    display: flex;
    height: 34px;
    justify-content: center;
    width: 34px;
  }

  &__marker-icon {
    font-size: 22px;
  }

  &__overlay {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__placeholder {
    background-color: $medium-grey;
    border-radius: 4px;
    display: block;
    height: 75px;
    width: 209px;
  }
}

.PreviewCard {
  @include focus-indicator-disappear($border-width);

  border: $border-width solid $dark-grey-blue;
  border-radius: 4px;
  padding: 1rem;
  width: 243px;

  &:hover {
    background-color: $light-blue;
  }

  &--keyboard-focused {
    &:focus {
      @include focus-indicator-appear($border-width);
      background-color: $light-blue;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__status {
    @include font-medium-uppercase;

    background-color: $light-blue;
    border: 1px solid $primary;
    border-radius: 999px;
    color: $primary;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
  }

  &__subtitle {
    @include ellipsize-text;
  }

  &__subtitle-aside {
    white-space: nowrap;
  }

  &__subtitle-row {
    @include font-medium-uppercase;

    color: $dark-grey;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__title {
    @include ellipsize-text;
    @include font-body-regular;
    color: $black;
    margin-bottom: 3px;
  }
}
