.ZoomControl {
  &_container {
    display: flex;
    position: absolute;
    right: 16px;
    bottom: 16px;
    background-color: $white;
    width: 290px;
    height: 96px;
    padding-top: 15.5px;
    padding-bottom: 15.5px;
    box-shadow: 0px 1px 3px $box-shadow;
    border-radius: 4px;
    z-index: 10;

    @include media-query-max-phone {
      top: 16px;
    };
  }

  &_panel {
    align-self: center;
    width: calc(100% / 3);
    height: 65px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;

    &:not(:last-of-type) {
      border-right: 2px solid $grey-blue;
    }
  }

  &_tile-button {
    @include focus-indicator-disappear;
    background: $light-blue; // Overlay hidden by image, shows on hover
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 4px;
    width: 65px;
    height: 65px;
    cursor: pointer;

    &:hover img {
      opacity: 0.8;
    }

    &--keyboard-focused {
      @include focus-indicator-appear;
    }
  }

  &_zoom-button {
    @include focus-indicator-disappear;
    @include font-size(36px);
    line-height: rem-calc(72px);
    background-color: $white;
    margin: 0;
    padding: 0;
    border: none;
    width: 65px;
    height: 65px;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background-color: $light-blue;
    }

    &:active {
      background-color: $light-grey;
    }

    &--keyboard-focused {
      &:focus {
        @include focus-indicator-appear-external-focus;
        background-color: $light-blue;
      }
    }
  }

  &_map {
    border-radius: 4px;
    width: 65px;
  }
}