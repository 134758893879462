$sensor-height: 100px;

.loader {
  justify-content: flex-start;
  padding-left: 32px;
}

.sensor {
  height: $sensor-height;
  margin-top: -$sensor-height;
  width: 100%;
}
