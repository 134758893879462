@import "abstracts/variables";

.container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.handleBar {
  background-color: $grey-blue;
  border-radius: 999px;
  height: 8px;
  width: 48px;
}
